<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t('message.product_list') }}</p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <div class="gc-card__body px-3 py-4" v-loading="loadingData">
            <div class="app-form__group mb-0">
                <div class="  gc-card rounded-sm mb-4 p-relative mr-3 px-3 py-3">
                    <el-table 
                        header-row-class-name="header__class"
                        row-class-name="row__class"
                        :data="dealProductsList" 
                        show-summary 
                        :summary-method="getSummaries" 
                        @selection-change="handleSelectionChange"
                        border stripe 
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55">
                        </el-table-column>
                        <el-table-column
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column :label="$t('message.deal')" width="90">
                            <template slot-scope="scope"> {{ scope.row.deal_id }} </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.reciever')">
                            <template slot-scope="scope"> 
                                <span v-show="scope.row.reciever">
                                    {{'ID: ' + scope.row.reciever.custom_id }} <br> {{ scope.row.reciever.name}} 
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.name')">
                            <template slot-scope="scope"> 
                                <span v-show="scope.row.type === 'product'">{{ scope.row.product ? scope.row.product.name : '' }} </span>
                                <span v-show="scope.row.type === 'package'">{{ $t('message.package') }} </span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.barcode')">
                            <template slot-scope="scope"> 
                                <span>{{ scope.row.barcode }} </span>    
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.quantity_y')">
                            <template slot-scope="scope"> 
                                <span>{{ scope.row.remainder }} </span>    
                                <span v-if="!scope.row.batches"> 1 </span>    
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('message.current_quantity')">
                            <template slot-scope="scope">
                                <div v-show="!scope.row.totalQuantity">
                                    <el-input
                                        :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                                        :min="0"
                                        type="number"
                                        size="mini"
                                        @input="checkValue(scope.row)"
                                        v-model="scope.row.incoming_quantity"
                                    ></el-input>
                                </div>
                                <span v-show="scope.row.totalQuantity">
                                    {{scope.row.totalQuantity}}
                                </span>
                            </template>
                        </el-table-column>

                        <el-table-column :label="columns.weight.title">
                            <template slot-scope="scope">
                                <div v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )">
                                    <el-input 
                                        :min="0" 
                                        :disabled="true"
                                        type="number" 
                                        size="mini" 
                                        v-model="scope.row.weight"
                                    ></el-input>
                                    <el-input
                                        type="text"
                                        size="mini"
                                        disabled
                                        :value="compareAndSetMeasureWord(scope.row)"
                                    ></el-input>
                                </div>

                                <span v-show="scope.row.type === 'package'"> {{ scope.row.total_weight }} </span>
                                <span v-show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"> {{ showWeight(scope.row) }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column :label="columns.item_weight.title">
                            <template slot-scope="scope">
                                <el-input 
                                    v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                                    :min="0" 
                                    :disabled="showInputCheck(scope.row)"
                                    @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                    type="number" 
                                    size="mini" 
                                    v-model="scope.row.item_weight"
                                ></el-input>
                            </template>
                        </el-table-column>

                        <el-table-column :label="columns.item_weight_in_pounds.title">
                            <template slot-scope="scope">
                                <el-input 
                                    v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 && !showInputCheck(scope.row))"
                                    :min="0" 
                                    @input="updatePoundAndKG(scope.row, 'item_weight_in_pounds')"
                                    type="number" 
                                    size="mini" 
                                    v-model="scope.row.item_weight_in_pounds"
                                ></el-input>
                            </template>
                        </el-table-column>

                        <el-table-column :label="columns.width.title" v-if="columns.width.show" width="90">
                            <template slot-scope="scope">
                                <el-input
                                    v-show="showInputCheck(scope.row)"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="compareAndSetWeight(scope.row)"
                                    v-model="scope.row.width"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="columns.height.title" v-if="columns.height.show" width="90">
                            <template slot-scope="scope">
                                <el-input
                                    v-show="showInputCheck(scope.row)"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="compareAndSetWeight(scope.row)"
                                    v-model="scope.row.height"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="columns.length.title" v-if="columns.length.show" width="90">
                            <template slot-scope="scope">
                                <el-input
                                v-show="showInputCheck(scope.row)"
                                :min="0"
                                type="number"
                                size="mini"
                                @input="compareAndSetWeight(scope.row)"
                                v-model="scope.row.length"
                                ></el-input>
                            </template>
                        </el-table-column>

                        <el-table-column :label="$t('message.comment')">
                            <template slot-scope="scope"> 
                                <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                                <span  v-show="!scope.row.hasOwnProperty('comment')">{{scope.row.containerType ? scope.row.containerType.name : ''}}</span>

                            </template>
                        </el-table-column>

                        <el-table-column :label="$t('message.update')" width="85">
                            <template slot-scope="scope"> 
                                <el-button v-if="scope.row.type == 'product' && scope.row.batches && scope.row.batches.length > 1"
                                    @click="showAndUpdateBatches(scope.row)" 
                                    type="primary" icon="el-icon-edit" circle>
                                </el-button> 
                                <el-button v-if="scope.row.type == 'package'"
                                    @click="showContainerProducts(scope.row)" 
                                    type="primary" icon="el-icon-view" circle>
                                </el-button> 
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="my___pagination">
                        <crm-pagination
                            @c-change="updatePagination"
                            :class="mode ? 'pagination__day' : 'pagination__night'"
                            :pagination="pagination"
                        ></crm-pagination>
                        <!-- <Pagination /> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Product partiyalar bo'yicha -->
        <el-dialog 
            :append-to-body="true"
            width="80%"
            :title="$t('message.product_inventorization')" 
            :visible.sync="batchDialog">
            <div v-if="selectedProduct.batches">
                <el-table :data="selectedProduct.batches" border stripe>
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                   <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column> 
                   <el-table-column :label="$t('message.name')">
                        <template> {{ selectedProduct.product ? selectedProduct.product.name : '' }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.quantity_y')">
                        <template slot-scope="scope"> {{ scope.row.remainder }} </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.barcode')">
                        <template slot-scope="scope"> {{ scope.row.barcode }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.current_quantity')">
                        <template slot-scope="scope">
                            <el-input
                                :min="0"
                                type="number"
                                size="mini"
                                @input="checkValue(scope.row)"
                                v-model="scope.row.incoming_quantity"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.weight.title">
                        <template slot-scope="scope">
                            <el-input 
                                :min="0" 
                                :disabled="true"
                                type="number" 
                                size="mini" 
                                v-model="scope.row.weight"
                            ></el-input>
                            <el-input
                                type="text"
                                size="mini"
                                disabled
                                :value="compareAndSetMeasureWord(scope.row)"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.item_weight.title">
                        <template slot-scope="scope">
                            <el-input 
                                :min="0" 
                                :disabled="true"
                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                type="number" 
                                size="mini" 
                                v-model="scope.row.item_weight"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.item_weight_in_pounds.title">
                        <template slot-scope="scope">
                            <el-input 
                                :min="0" 
                                type="number" 
                                size="mini" 
                                @input="updatePoundAndKG(scope.row, 'item_weight_in_pounds')"
                                v-model="scope.row.item_weight_in_pounds"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.width.title" v-if="columns.width.show" width="90">
                        <template slot-scope="scope">
                            <el-input
                                :min="0"
                                type="number"
                                size="mini"
                                @input="compareAndSetWeight(scope.row)"
                                v-model="scope.row.width"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.height.title" v-if="columns.height.show" width="90">
                        <template slot-scope="scope">
                            <el-input
                                :min="0"
                                type="number"
                                size="mini"
                                @input="compareAndSetWeight(scope.row)"
                                v-model="scope.row.height"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="columns.length.title" v-if="columns.length.show" width="90">
                        <template slot-scope="scope">
                            <el-input
                            :min="0"
                            type="number"
                            size="mini"
                            @input="compareAndSetWeight(scope.row)"
                            v-model="scope.row.length"
                            ></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.comment')">
                        <template slot-scope="scope"> {{ scope.row.comment }} </template>
                    </el-table-column>
                </el-table>

                <div class="mt-4 cargo__right">
                    <el-button @click="updateIncomingProducts()" type="primary" plain>{{ $t('message.save') }}</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- Upakovka productlari -->
        <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%" @closed="emptyCortainerProducts()" :append-to-body="true" >
            <div v-loading="loadingProducts">
                <el-table :data="containerProducts">
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="comment" :label="$t('message.comment')">
                        <template slot-scope="scope">
                            {{ scope.row.comment ? scope.row.comment : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="barcode" :label="$t('message.barcode')"></el-table-column>
                    <el-table-column prop="remainder" :label="$t('message.quantity')"></el-table-column>

                    <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                    <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { formatMoney, formatNumber } from "@/filters/index";
import product_calculation_functions from "@/utils/mixins/product_calculation_functions";

export default {
    mixins: [form, drawer, product_calculation_functions],
    props: {
      payload_for_prod_list: {
        default: {}
      },
    },
    components: {
    },
    data: () => ({
        mode: true,
        deal_id: null,
        product_id: null,
        dealProductsList: [],
        batchDialog: false,
        selectedProduct: {},
        loadingProducts: false,
        loadingData: false,
        dialogProductsList: false,
        selectedProducts: []
    }),
    watch: {
        'pagination.page': {
            handler: async function(newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
        'pagination.per_page': {
            handler: async function(newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            containerProducts: "packageForProductCirculations/products",
            authUser: "auth/user",
            rules: "containers/rules",
            model: "containers/model",
            columns: "dealProducts/columns",
            pagination: "containers/filial_products_pagination", 
            allPackagesAndProducts: 'containers/allPackagesAndProducts'
        }),
        total_weight: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                if(product.type === 'product'){
                    if(product.batches && product.batches.length > 1){
                        product.batches.forEach(batch => {
                            total += parseFloat(batch.weight);
                        });
                    }else{
                        total += parseFloat(product.weight);
                    }
                }
                if(product.type === 'package'){
                    total += parseFloat(product.total_weight);
                }
                
            });
            return _.round(parseFloat(total) ,2);
        },
    },
    created(){
        this.debouncedFetchData = _.debounce(this.showDirectionProducts, 500);
    },
    methods: {
        ...mapActions({
            save: "containers/store",
            showProducts: "packageForProductCirculations/showProducts",
            emptyCortainerProducts: "packageForProductCirculations/empty",
            updatePagination: "containers/updateFilialProductsPagination",
            getAllPackageAndProductsToSend: 'containers/getAllPackageAndProductsToSend',
        }),
        handleSelectionChange(selected) {
            this.selectedProducts = selected;
        },
        
        async afterOpen(){
            await this.updatePagination({ key: "page", value: 1 });
            if (_.isFunction(this.debouncedFetchData)) {
                this.debouncedFetchData();
            }
        },
        showDirectionProducts(){
            const query = {...this.payload_for_prod_list, ...this.pagination}; 
            this.loadingData = true;
            this.getAllPackageAndProductsToSend(query)
            .then(res => {
                this.dealProductsList = JSON.parse(JSON.stringify(this.allPackagesAndProducts));
                this.loadingData = false;
            }).catch(err => {
                this.loadingData = false;
            });
        },
        afterLeave(){
            this.deal_id = null;
            this.product_id = null;
            this.dealProductsList = [];
        },
        async updateIncomingProducts(){
            await this.updateBatches();
            this.selectedProduct = {};
            this.batchDialog = false;
        },
        showContainerProducts(pack){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showProducts(pack.id).then(res => {
                    this.loadingProducts = false;
                }).catch(err => {
                    this.loadingProducts = false;
                });
        },
        updateBatches(){
            if(this.selectedProduct && this.selectedProduct.batches){
                this.dealProductsList.forEach(el => {
                    if(el.id === this.selectedProduct.id){
                        this.$set(el, 'batches', this.selectedProduct.batches);
                        
                        let total_quantity = 0;
                        this.selectedProduct.batches.forEach(batch => {
                            total_quantity +=  parseFloat(batch.incoming_quantity);
                        });
                       
                        this.$set(el, 'incoming_quantity', parseFloat(total_quantity));
                    }
                });
            }
        },
        showAndUpdateBatches(row){
            this.batchDialog = true;
            this.selectedProduct = JSON.parse(JSON.stringify(row));
        },
        showWeight(row){
            if(row.batches && row.batches.length > 1){
                let weights = 0;
                row.batches.forEach(element => {
                    weights += parseFloat(element.weight)
                });
                return _.round(weights, 6) ;
            }else{
                return row.weight;
            }
        },
        checkValue(row){
            if(parseFloat(row.incoming_quantity) > parseFloat(row.remainder) || parseFloat(row.incoming_quantity) < 0){
                this.$set(row, 'incoming_quantity', parseFloat(row.remainder))
            }
            if(!row.incoming_quantity){
                this.$set(row, 'incoming_quantity', parseFloat(0))
            }
        },
        showInputCheck(row){
            if(row.type === 'product' && (!row.batches || row.batches.length <= 1)) {
                return true;
            }
            return false;
        },
        insufficiant_prod_message(){
            this.$notify({
                title: this.$t('message.product'),
                type: "error",
                offset: 130,
                message: this.$t('message.insufficient_goods')
            });
        },
        submit(close = true) {
            this.$emit('mergeToProductList', this.selectedProducts);
            this.selectedProducts = [];
            this.dealProductsList = [];
            this.close();
        },
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label === this.columns.weight.title) {
                    sums[index] = formatNumber(this.total_weight, 1) + ' ' + this.$t('message.kg');
                    return;
                }
               
                if (column.label === (this.columns.weight.title + ' %')) {
                    sums[index] = formatMoney(this.weight, 2);
                    return;
                }
            });

            return sums;
        },
    },
    
}
</script>

<style>

</style>
 