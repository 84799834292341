<template>
    <el-dialog :append-to-body="true" :title="$t('message.dealServices') + ' №' + self_deal_id" :visible.sync="dialogChangeProcess">
      <div v-loading="loadingData">
        <el-row :gutter="20">
            <span v-if="dealServices && dealServices.length == 0" class="font-bold">
              {{ $t('message.there_is_no_services') }}
            </span>
            <el-col v-for="(service, index) in dealServices" :key="'key_' + index" :span="12" class="mb-4">
                <span class="font-bold">{{ service.name }}: </span>  
                <span :class="processServiceClass(service.process)">
                  {{ service.process ? service.process.name : ''}}
                </span>
            </el-col>
        </el-row>
      </div>
    </el-dialog>
</template>
  
<script>
  // @ is an alias to /src
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    name: "showDealServices",
    props: {
        deal_id: {
            default: null
        },
    },
    data() {
      return {
        loadingData: false,
        dialogChangeProcess: false,
        self_deal_id: null
      };
    },

    watch: {
        deal_id: {
            handler: async function(newVal, oldVal) {
              if(newVal){
                this.self_deal_id = newVal;
                this.loadingData = true;
                this.dialogChangeProcess = true;
                this.showDealServices({deal_id: newVal})
                    .then(res => {
                      this.loadingData = false;
                    })
                    .catch(err => {
                      this.loadingData = false;
                    });
              }
            },
            deep: true,
            immediate: true
        },
    },
  
    computed: {
      ...mapGetters({
        mode: "MODE",
        dealServices: "dealService/dealServices",
      }),
    },

    methods: {
      ...mapActions({
        showDealServices: "dealService/showDealServices",
      }),
      processServiceClass(process) { 
        if(process && process.id){
          if(process.id == 1){
            return 'mm_active';
          }else if(process.id == 2){
            return 'mm_process';
          }else if(process.id == 3){
            return 'mm_success';
          }
        }
        return '';
      }
    },
  };
</script>
<style>
  .mm_active{
      color: #E6A23C;
  }
  .mm_process{
      color: #409EFF;
  }
  .mm_success{
      color: #67C23A;
  }
  .link{
      text-decoration: none;
  }
</style>
  
  